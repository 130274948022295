import React, { ReactNode } from "react"

import ThemeProvider from "@material-ui/styles/ThemeProvider"
import { redTheme } from "@ecom/ui/styles/themes/red"
import { SEO, SEOProps } from "../SEO"
import { FooterProps } from "../Footer"

export type LayoutProps = {
  children: ReactNode
  seoData?: SEOProps
  phones?: FooterProps["phones"]
  shortText?: string
  withPhone?: FooterProps["withPhone"]
  hasCustomFooter?: boolean
  favicon?: string
}

export function LayoutBase({ children, seoData, favicon }: LayoutProps) {
  return (
    <ThemeProvider theme={redTheme}>
      <SEO title={seoData?.title} description={seoData?.description} favicon={favicon} />
      <div style={{ overflow: "hidden" }}>{children}</div>
    </ThemeProvider>
  )
}
