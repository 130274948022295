import React from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./promotion.module.scss"
import img1 from "./img/Img_3000/img1.svg"
import img2 from "./img/Img_3000/img2.svg"
import img3 from "./img/Img_3000/img3.svg"
import { getDatesFromQuery } from "../../../utils/getDatesFromQuery"

export function Promotion() {
  const dates = getDatesFromQuery()

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <img src={img1} alt="img" className={styles.img} />
        <div className={styles.text}>
          Совершите от 5 покупок* собственными или заемными средствами на общую сумму от 10 000
          руб.** в Ваш текущий отчетный период (с {dates[0]} по {dates[1]}) и верните*** до 500
          баллов!
        </div>
        <img src={img2} alt="img" className={styles.img} />
        <div className={styles.text}>
          Продолжите выполнять условия в следующий отчетный период (с {dates[2]} по {dates[3]}) и
          верните*** до 1000 баллов!
        </div>
        <img src={img3} alt="img" className={styles.img} />
        <div className={styles.text}>
          А если Вы выполните условия и в третий отчетный период (с {dates[4]} по {dates[5]}), то
          сможете вернуть*** до 1500 баллов!
        </div>
      </Container>
    </section>
  )
}
