import React, { useEffect } from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import sendRequest from "@ecom/ui/utils/sendRequest"
import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import { navigate } from "gatsby"

import * as styles from "./registration.module.scss"

export type RegistrationProps = {
  link?: string
}

export function Registration({ link }: RegistrationProps) {
  useEffect(() => {
    sendRequest(`${process.env.GATSBY_APP_URL_API}/v1/halvabonus`, {
      additionalData: getAdditionalData(),
      action: "visit",
    })
  }, [])

  const onClick = () => {
    sendRequest(`${process.env.GATSBY_APP_URL_API}/v1/halvabonus`, {
      additionalData: getAdditionalData(),
      action: "registered",
    })
      .then(() => navigate("/halvabonus/success"))
      .catch(() => navigate("/halvabonus/error"))
  }

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.title}>Для регистрации в акции обязательно нажмите кнопку</div>
        <Button className={styles.btn} onClick={onClick}>
          я участвую
        </Button>
        {link && (
          <div className={styles.linkBlock}>
            Полные условия{" "}
            <a href={link} className={styles.link} target="_blank" rel="noreferrer">
              по ссылке
            </a>
          </div>
        )}
      </Container>
    </section>
  )
}
