export function getDatesFromQuery() {
  if (typeof window === "undefined") {
    return []
  }
  const url = new URL(window.location.href)

  const searchParams: any = {}

  url.searchParams.forEach((value, index) => {
    searchParams[index] = value
  })

  const dates = []

  for (let i = 1; i < 7; i += 1) {
    const date = new Date(searchParams[`d${i}`] || null)
    dates.push(`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`)
  }

  return dates
}
