import React from "react"
import Container from "@ecom/ui/components/Container"

import * as styles from "./footer.module.scss"

type FooterProps = {
  thirdStar?: string
}

export function Footer({ thirdStar }: FooterProps) {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <p style={{ marginBottom: 16 }}>
          * Или платежей в разделе «Оплата услуг» в приложении «Совкомбанк – Халва», или переводов /
          операций по снятию заемных средств.
          <br />
          ** Не учитываются операции, указанные в пп.1.1.5.4-1.1.5.9 паспорта, для Социальной Халвы
          – 5000 р.
          <br />
          ***{" "}
          {thirdStar ||
            `Возврат осуществляется путем предоставления промокода на кэшбэк 50% от суммы покупки у
                партнeров Халвы. Промокод можно будет применить в течение месяца c момента получения.
                Промокод будет отправлен Вам в течение 2 недель с момента окончания отчетного периода.`}
          <br />
        </p>
        <p>
          Отслеживать выполнение условий можно в мобильном приложении «Совкомбанк –Халва» в разделе
          Профиль → «Бонус 5 на 10»
        </p>
      </Container>
    </section>
  )
}
