import React from "react"

import * as styles from "./banner.module.scss"

import banner from "./img/banner.svg"
import bannerH from "./img/bannerH.svg"

export type BannerProps = {
  title?: JSX.Element | string
  subTitle?: string
  hasHabenskiy?: boolean
}

export function Banner({ title, subTitle, hasHabenskiy }: BannerProps) {
  const img = hasHabenskiy ? bannerH : banner
  return (
    <section className={styles.section}>
      <img src={img} alt="banner" className={styles.img} />
      {title && <div className={styles.title}>{title}</div>}
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
    </section>
  )
}
