import React from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../components/Layout"
import { Banner } from "../components/halvabonus/Banner"
import { Promotion } from "../components/halvabonus/Promotion/Promotion_3000"
import { Registration } from "../components/halvabonus/Registration"
import { Footer } from "../components/halvabonus/Footer/Main"
import { PageData } from "../interfaces/pageProps"
import { getPageData } from "../helpers/getPageData"

export default function Page({ data }: PageProps<PageData>) {
  const { seoData, ligal } = getPageData(data)
  const isMobile = useMediaQuery("(max-width:599px)")

  return (
    <Layout seoData={seoData} shortText={ligal.shortText} hasCustomFooter>
      <Banner
        title={
          isMobile ? (
            <>
              ХАЛВА дарит
              <br /> до 3000 баллов!
            </>
          ) : (
            "ХАЛВА дарит до 3000 баллов!"
          )
        }
      />
      <Promotion />
      <Registration />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    pageDataJson(pageName: { eq: "halvabonus-3000" }) {
      seoData {
        title
        description
      }
    }
  }
`
